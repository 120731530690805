<template>
  <div class="home">
    <div class="info-container">
      <b-row class="my-row" :style="[ loginFooter ? { 'margin-bottom': '5rem' } : { 'margin-bottom': '0' } ]">
        <b-col cols="4" class="logo-column">
          <div v-if="contrast">
            <img :src="require('../assets/images/login/logo-text-white.svg')" class="logo-text" alt="Logo"/>
          </div>
          <div v-else>
            <img :src="require('../assets/images/login/logo-text.svg')" class="logo-text" alt="Logo"/>
          </div>
          <div class="info-box">
            <b-row class="oneline">
              <b-col cols="2">
                <div style="min-width: 40px; margin-left: 21px">
                  <div class="my-square"></div>
                </div>
              </b-col>
              <b-col cols="10">
                <div class="info-text">{{ $t('views.login.tool') }}</div>
              </b-col>
            </b-row>
            <b-row class="oneline">
              <b-col cols="2">
                <div style="min-width: 40px; margin-left: 11px">
                  <div class="my-square"></div>
                  <div class="my-square"></div>
                </div>
              </b-col>
              <b-col cols="10">
                <div class="info-text">{{ $t('views.login.monitoring') }}</div>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="2">
                <div style="min-width: 40px">
                  <div class="my-square"></div>
                  <div class="my-square"></div>
                  <div class="my-square"></div>
                </div>
              </b-col>
              <b-col cols="10">
                <div class="info-text">{{ $t('views.login.algorithm') }}</div>
              </b-col>
            </b-row>
          </div>
        </b-col>
        <b-col cols="8">
          <div class="logo-human-container">
            <img :src="require('../assets/images/login/logo-human.svg')" class="logo-human" alt="Logo"/>
          </div>
        </b-col>
      </b-row>
      <div v-if="loginFooter" class="footer-container" v-html="loginFooter"></div>
    </div>
  </div>
</template>

<script>
import ApiStatistics from '../api/statistics.js'
import AcademyApi from '../api/academies'
import WindowResizeMixin from 'pcg-core-vue/src/mixins/window_resize'
import { mapGetters } from 'vuex'
import Vue from 'vue'

export default {
  name: 'login',
  mixins: [WindowResizeMixin],
  data: function () {
    return {
      offersCount: 0,
      companiesCount: 0,
      studentsAndGraduatesCount: 0,
      loginFooter: null
    }
  },
  components: { },
  created () {
    AcademyApi.getFooter()
      .then(response => {
        this.loginFooter = response.data.footerLogin
      })
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
    this.loginCallback()
    // this.getBasicInfo()
    this.$store.dispatch('header/setHeader', {
      title: null
    })
  },
  watch: {
    windowWidth (newValue) {
      this.setBackgroundImageStyle()
    },
    contrast (newValue) {
      newValue ? this.clearBackgroundImage() : this.setBackgroundImage()
    }
  },
  computed: {
    ...mapGetters('page', ['contrast']),
    ...mapGetters('auth', ['signedIn', 'roles', 'currentRole', 'fullName', 'employeeId', 'rights', 'expire']),
    isIos () {
      return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
    }
  },
  methods: {
    getBasicInfo () {
      ApiStatistics.getBasicInfo()
        .then(response => {
          const { acceptedCompaniesTotalCount, acceptedOffersTotalCount, studentsOrGraduatesTotalCount } = response.data
          this.offersCount = acceptedOffersTotalCount
          this.companiesCount = acceptedCompaniesTotalCount
          this.studentsAndGraduatesCount = studentsOrGraduatesTotalCount
        })
    },
    login () {
      window.location = `${process.env.SERWER_ADDRESS_WITH_PROTOCOL}/users/auth/openid_connect`
    },
    loginCallback () {
      if (this.$route.query.code) {
        const query = { ...this.$route.query }
        this.$router.replace({ query: {} })
        console.log('LoginCallback: ', query)
        this.$store.dispatch('auth/signIn', query).then(response => {
          console.log('LoginCallback success', response)
          if (this.isIos) {
            setTimeout(() => {
              const redirectTo = this.$store.getters['auth/getRedirectTo']
              if ((!Array.isArray(this.$store.getters['auth/roles']) || !this.$store.getters['auth/roles'].length)) {
                this.$router.push({ name: 'no_rights_path' }).catch(() => {})
              } else if (redirectTo && redirectTo.name) {
                this.$store.dispatch('auth/clearRedirectTo')
                this.$router.push(redirectTo)
              } else if (redirectTo) {
                this.$store.dispatch('auth/clearRedirectTo')
                this.$router.push(redirectTo)
              } else {
                const userRole = this.$store.getters['auth/currentRole']
                if (['naukowiec'].includes(userRole)) {
                  // this.$router.push({ name: 'scientist_dashboard_path' })
                  this.$router.replace('/scientist_dashboard')
                } else if (userRole === 'kierownikdyscypliny') {
                  // this.$router.push({ name: 'root_path' })
                  this.$router.replace('/')
                } else if (userRole === 'analityknaukowy') {
                  // this.$router.push({ name: 'root_path' })
                  this.$router.replace('/')
                } else if (userRole === 'pracownik' || userRole === 'pracownikjednostki') {
                  // this.$router.push({ name: 'root_path' })
                  this.$router.replace('/')
                } else {
                  // this.$router.push({ name: 'root_path' })
                  this.$router.replace('/')
                }
              }
            }, 2000)
          } else {
            const redirectTo = this.$store.getters['auth/getRedirectTo']
            if ((!Array.isArray(this.$store.getters['auth/roles']) || !this.$store.getters['auth/roles'].length)) {
              this.$router.push({ name: 'no_rights_path' }).catch(() => {})
            } else if (redirectTo && redirectTo.name) {
              this.$store.dispatch('auth/clearRedirectTo')
              this.$router.push(redirectTo)
            } else if (redirectTo) {
              this.$store.dispatch('auth/clearRedirectTo')
              this.$router.push(redirectTo)
            } else {
              const userRole = this.$store.getters['auth/currentRole']
              if (['naukowiec'].includes(userRole)) {
                // this.$router.push({ name: 'scientist_dashboard_path' })
                window.location = '/scientist_dashboard'
              } else if (userRole === 'kierownikdyscypliny') {
                // this.$router.push({ name: 'root_path' })
                window.location = '/'
              } else if (userRole === 'analityknaukowy') {
                // this.$router.push({ name: 'root_path' })
                window.location = '/'
              } else if (userRole === 'pracownik' || userRole === 'pracownikjednostki') {
                // this.$router.push({ name: 'root_path' })
                window.location = '/'
              } else {
                // this.$router.push({ name: 'root_path' })
                window.location = '/'
              }
            }
          }
        }).catch(error => {
          if (error && error.response) {
            console.log('Error LoginCallback', error.response)
          } else {
            console.log(error)
          }
          this.$toastr.e(error)
          Vue.$cookies.set('wrong_login', error.response.data.email)
        })
      }
    },
    setBackgroundImageStyle () {
      this.$store.dispatch('pageCover/setBackgroundImageStyle', {
        height: '100% !important'
      })
    },
    setBackgroundImage () {
      if (!this.contrast) {
        this.$store.dispatch('pageCover/setBackgroundImage', require('../assets/images/Blank.jpeg'))
      }
    },
    setBackgroundImageClasses () {
      this.$store.dispatch('pageCover/setBackgroundImageClasses', ['no-gradient'])
    },
    setSidebarMenuClasses () {
      this.$store.dispatch('sidebarMenu/setSidebarMenuClasses', ['no-box-shadow'])
    },
    clearBackgroundImage () {
      this.$store.dispatch('pageCover/clearBackgroundImage')
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setBackgroundImage()
      vm.setBackgroundImageStyle()
      vm.setBackgroundImageClasses()
      vm.setSidebarMenuClasses()
    })
  }
}
</script>

<style lang="scss" scoped>
  @import '../assets/stylesheets/vars';

  /deep/ .pcg-btn-sidebar-menu {
    display: none !important;
  }
  .info-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
  .buttons-container {
    width: fit-content;
    margin-left: auto;
    .buttons-separator {
      margin: 0 $pcg-m-lg;
      color: $pcg-gray;
      font-weight: 500;
    }
    button {
      width: 190px;
      margin: 0;
    }
  }
  .home {
    position: relative;
    color: $pcg-the-darkest-gray;
    transform: translateY(-20px);
  }
  .big-circle-container {
    position: relative;
    max-width: 530px;
    margin-left: auto;
    margin-right: auto;
    z-index: 10;
    &:after {
      content: $pcg-icon-bullet;
      position: absolute;
      bottom: -31px;
      left: 0;
      right: 0;
      color: $pcg-pink-color;
      font-size: 40px;
    }
  }
  .student-image {
    width: 100px;
    margin-bottom: $pcg-m-xxl;
  }
  .small-circle-container {
    width: 325px;
  }
  .circle {
    position: relative;
    width: 100%;
    height: 0;
    padding: 12% 0 88%;
    border-radius: 50%;
    border: 1px solid $pcg-medium-lightest-gray;
  }
  .text {
    line-height: 1.3;
    font-weight: 500;
    margin-left: auto;
    margin-right: auto;
  }
  .big-circle {
    text-align: center;
    .text {
      font-size: $font-size-m;
      max-width: 300px;
    }
  }
  .header {
    font-weight: bold;
    margin-bottom: $pcg-m-xl;
  }
  .info {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
    display: flex;
    align-items: center;
    bottom: -198px;
    width: 100%;
    max-width: 800px;
    left: 0;
    right: 0;
  }
  .side-info {
    width: 165px;
    height: 300px;
    padding-top: 36px;
  }
  .info-content {
    display: flex;
    flex-direction: column;
    .text {
      width: 145px;
      font-size: $font-size-s;
    }
  }
  .icon {
    min-height: 30px;
    margin-bottom: $pcg-m-sm;
    background-color: $main-active-color;
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center;
  }
  .icon-student {
    mask-image: url('../assets/images/icons/student-blue.svg');
  }
  .icon-buildings {
    mask-image: url('../assets/images/icons/buildings-blue.svg');
  }
  .icon-bookmark {
    mask-image: url('../assets/images/icons/bookmark-blue.svg');
  }
  .number {
    font-size: $font-size-xxl;
    font-weight: 500;
    color: $main-active-color;
  }
  .number, .icon {
    margin-bottom: 10px;
  }
  .info-title {
    font-size: $font-size-xl;
    font-weight: bold;
    margin-bottom: $pcg-m-md;
  }
  @media (max-width: 1040px) {
    .info {
      flex-direction: column;
      justify-content: flex-start;
      bottom: -556px;
    }
    .info-content {
      height: fit-content;
    }
    .small-circle {
      width: 281px;
      padding: 30px;
      margin: 0 auto;
    }
    .offers-count-info {
      order: 2;
    }
    .companies-count-info {
      order: 1
    }
    .students-count-info {
      order: 3
    }
    .header {
      font-size: $font-size-xl;
    }
    .student-image {
      width: 90px;
      margin-bottom: $pcg-m-lg;
    }
  }
  @media (max-width: 510px) {
    .small-circle {
      width: 250px;
      padding: 20px;
    }
    .big-circle-container {
      max-width: 100%;
    }
    .info {
      bottom: -626px;
    }
    .info-title {
      margin-bottom: $pcg-m-xs;
    }
    .header {
      margin-bottom: $pcg-m-sm;
    }
    @-moz-document url-prefix() {
      .info {
        bottom: -635px!important;
      }
    }
  }

  .logo-text {
    width: 300px;
    margin-top: 50px;
    z-index: 2;
    @media (max-width: 990.98px) {
      width: 250px;
    }
    @media (max-width: 767.98px) {
      width: 250px;
    }
    @media (max-width: 600.98px) {
      width: 200px;
    }
  }

  .logo-human {
    margin-top: 20px;
    width: 400px;
    height: 410px;
    position: relative;
    z-index: -1;
    @media (max-width: 1280.98px) {
      left: 2rem;
    }
    @media (max-width: 1100.98px) {
      left: 4rem;
    }
    @media (max-width: 1050.98px) {
      left: 5rem;
    }
    @media (max-width: 990.98px) {
      width: 350px;
      height: 358px;
    }
    @media (max-width: 870.98px) {
      width: 300px;
      height: 308px;
    }
    @media (max-width: 830.98px) {
      width: 250px;
      height: 256px;
    }
   @media (max-width: 800.98px) {
     width: 200px;
     height: 205px;
     left: 0;
     top: 2rem;
   }
   @media (max-width: 767.98px) {
     width: 200px;
     height: 205px;
     left: 3rem;
   }
   @media (max-width: 680.98px) {
     left: 5rem;
   }
   @media (max-width: 600.98px) {
     width: 150px;
     height: 154px;
     left: 3rem;
   }
   @media (max-width: 525.98px) {
     left: 5rem;
   }
  }

  .logo-human-container {
    display: flex;
    justify-content: center;
    @media (max-width: 767.98px) {
      justify-content: start;
    }
  }

  .info-box {
    background-color: $pcg-lightest-gray-background;
    padding: 10px 20px;
    border-radius: 10px;
    margin-top: 3rem;
    position: relative;
    left: 5.5rem;
    max-width: 340px;
    min-width: 340px;
    @media (max-width: 990.98px) {
      left: 4.5rem;
      max-width: 300px;
      min-width: 300px;
    }
    @media (max-width: 800.98px) {
      left: 4.5rem;
      max-width: 400px;
      min-width: 400px;
      margin-top: 6.5rem;
    }
    @media (max-width: 767.98px) {
      left: 4.5rem;
      max-width: 400px;
      min-width: 400px;
      margin-top: 6.5rem;
    }
    @media (max-width: 600.98px) {
      left: 3.5rem;
      max-width: 340px;
      min-width: 340px;
      margin-top: 5.5rem;
    }
    @media (max-width: 440.98px) {
      max-width: 300px;
      min-width: 300px;
    }
    @media (max-width: 440.98px) {
      max-width: 240px;
      min-width: 240px;
    }
  }

  .info-text {
    font-weight: 400;
  }

  .my-square {
    width: 7px;
    height: 7px;
    background-color: $main-active-color;
    display: inline-block;
  }

  .oneline {
    margin-bottom: 1rem;
  }

  .my-row {
    margin-top: 3rem;
  }

  .logo-text-container {
    margin-bottom: 1rem;
  }

  .logo-column {
    padding-left: 100px;
    @media (max-width: 1180.98px) {
      padding-left: 50px;
    }
    @media (max-width: 1050.98px) {
      padding-left: 30px;
    }
    @media (max-width: 767.98px) {
      padding-left: 20px;
    }
  }

  .footer-container {
    margin-left: 1rem;
    margin-bottom: 1rem;
    padding: 1.5rem;
    background-color: $pcg-white;
    font-size: 0.86em;
    border-radius: 5px;
  }

  .my-text {
    font-size: $font-size-s;
  }
</style>

<style lang="scss" scoped>
  @import "../assets/stylesheets/vars-contrast";

  .contrast {
    .buttons-container {
      .buttons-separator {
        color: $pcg-gray;
      }
    }
    .home {
      color: $pcg-the-darkest-gray;
    }
    .big-circle-container {
      &:after {
        color: $pcg-pink-color;
      }
    }
    .circle {
      border: 1px solid $pcg-light-gray;
    }
    .number {
      color: $main-active-color;
    }
    .icon {
      background-color: $main-active-color;
    }
    .my-square {
      background-color: $main-active-color;
    }
    .info-box {
      background-color: $pcg-lightest-gray;
    }
    .footer-container {
      background-color: $pcg-white;
    }
  }
</style>
